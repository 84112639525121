export const types = {
	login: '[auth] login',
	logout: '[auth] logout',

	config_link_shop: '[config] link',

	// Search
	search_main_results: '[search] super_search_results',
	search_main_selected: '[search] super_search_selected',

	run_loader: '[loader] run_loader',

	account_status: '[account] status',
};
