interface DataForm {
  name: any
  localName: any
  type: any
  files: any
  checked: any
  value: any
}
class Processing {
  async toObject(dataForm: any) {
    var obj: any = {}
    await Promise.all(
      Object.keys(dataForm).map(async (index) => {
        var i: DataForm = dataForm[index]
        if (
          i.localName === 'select' ||
          i.localName === 'input' ||
          i.localName === 'textarea'
        ) {
          if (i.type === 'file' && typeof i.files[0] !== 'undefined') {
            var name = i.files[0].name
            var promise = await this.getBase64(i.files[0]);
            //var promise = await  this.getBase64(i);
            obj[i.name] = {
              file: promise,
              name,
            }
          } else {
            var valueField = i.value === '' ? null : i.value;
            if (i.type !== 'checkbox' && i.type !== 'radio' && i.name !== '') {
              if (i.name.match(/^.*\[\].*$/)) {
                if (typeof obj[i.name] === 'undefined') {
                  obj[i.name] = []
                }
                obj[i.name].push(valueField)
              } else {
                obj[i.name] = valueField
              }
            }
            if (i.type === 'checkbox') {
              if (i.checked === true) {
                obj[i.name] = valueField
              }
            }
            if (i.type === 'radio') {
              if (i.checked === true) {
                obj[i.name] = valueField
              }
            }
          }
        }
        return null
      })
    )
    return obj
  }

  validate(dataForm: HTMLInputElement, validations: any) {
    if (dataForm === null) {
      return false
    }
    const elements = dataForm.querySelectorAll('.is-invalid');
    elements.forEach(element => {
      element.classList.remove('is-invalid');
      const p: ParentNode | any = element.parentNode
      const f = p.getElementsByClassName('invalid-feedback')
      if (f.length > 0) f[0].remove()
    });

    Object.keys(validations).map((name, value: any) => {
      const item = dataForm.querySelector(`[name="${name}"]`)
      if (item) {
        item.classList.add("is-invalid")
        var wrapper = document.createElement('div');
        wrapper.innerHTML = validations[name][0] ?? ''
        wrapper.className = 'invalid-feedback'
        const parent: ParentNode | any = item.parentNode
        const feed = parent.getElementsByClassName('invalid-feedback')
        if (feed.length > 0) feed[0].remove()
        if (parent) parent.appendChild(wrapper)
      }
      return true
    })
    // Object.keys(dataForm).map((index) => {
    //   var i = dataForm[index]
    //   var parent = i.parentNode;
    //   if (
    //     typeof parent === 'object' &&
    //     (
    //       i.localName === 'select' ||
    //       i.localName === 'input' ||
    //       i.localName === 'textarea'
    //     )
    //   ) {
    //     var feed = parent.getElementsByClassName('invalid-feedback');
    //     var search = (i.name in validations)
    //     i.className = i.className.replace("is-invalid", "")
    //     parent.className = parent.className.replace("form-check-invalid", "")
    //     if (feed.length > 0) {
    //       feed[0].remove()
    //     }
    //     if (search) {
    //       var wrapper = document.createElement('div');
    //       wrapper.innerHTML = validations[i.name][0].replace(
    //         'Recupera tu contraseña ahora',
    //         '<a href="/enviar/mail"><small>Recupera tu contraseña ahora</small></a>'
    //       )
    //       wrapper.className = 'invalid-feedback'
    //       if (parent.classList.contains("form-check-inline")) {
    //         parent.classList.add("form-check-invalid")
    //       }
    //       parent.appendChild(wrapper)
    //       //i.className = 'form-control is-invalid'
    //       i.classList.add("is-invalid")

    //     }
    //   }
    //   return true
    // })
  }

  async getBase64(file: any) {
    return await new Promise(async function (resolve, reject) {
      var reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onloadstart = function (data) {
        if (data.lengthComputable) {
        }
      }
      reader.onprogress = function (data) {
        if (data.lengthComputable) {
        }
      }
      reader.onloadend = async function (data) {
        if (data.lengthComputable) {
        }
      }
      reader.onerror = reject;
      reader.readAsDataURL(file);
    }).catch(error => console.log(error));
  }

}
export default Processing;