import { lazy } from 'react';
import LayoutAuth from '../../components/Layouts/LayoutAuth';
import Loadable from '../../components/Loadings/Loadable'

const Login = Loadable(lazy(() => import('../../pages/Auth/Login')));
const SingUp1 = Loadable(lazy(() => import('../../pages/Auth/SingUp1')));
const SingUp2 = Loadable(lazy(() => import('../../pages/Auth/SingUp2')));
const Empresa = Loadable(lazy(() => import('../../pages/Auth/Empresa')));
const Persona = Loadable(lazy(() => import('../../pages/Auth/Persona')));
const TypeJob = Loadable(lazy(() => import('../../pages/Auth/TypeJob')));
const JobProfile = Loadable(lazy(() => import('../../pages/JobProfile')));
const Finish = Loadable(lazy(() => import('../../pages/JobProfile/Finish')));
const RecoveryPassword = Loadable(lazy(() => import('../../pages/Auth/RecoveryPassword')));
const ChangePassword = Loadable(lazy(() => import('../../pages/Auth/ChangePassword')));
const AuthGoogle = Loadable(lazy(() => import('../../pages/Auth/AuthGoogle')));
const FormDisc = Loadable(lazy(() => import('../../pages/Person/FormDisc')));
const Page404 = Loadable(lazy(() => import('../../pages/404')));
const Activate = Loadable(lazy(() => import('../../pages/Activate')));

const authRoute: any = {
  path: '/',
  element: <LayoutAuth />,
  children: [
    { path: "/", element: <Login /> },
    { path: "/login", element: <Login /> },
    { path: "/activar/cuenta", element: <Login /> },
    { path: "/recuperar/clave", element: <RecoveryPassword /> },
    { path: "/cambiar/clave", element: <ChangePassword /> },
    { path: "/auth/google", element: <AuthGoogle /> },
    { path: "/auth/facebook", element: <AuthGoogle /> },

    { path: "/registrarme", element: <SingUp1 /> },
    { path: "/registrarme/2", element: <SingUp2 /> },
    { path: "/registrarme/empresa", element: <Empresa /> },
    { path: "/registrarme/usuario", element: <Persona /> },
    { path: "/registrarme/tipo/trabajo", element: <TypeJob /> },
    { path: "/perfilamiento/:option", element: <JobProfile /> },
    { path: "/finalizar", element: <Finish /> },

    { path: "/formulario/disc", element: <FormDisc /> },
    { path: '/oferta/:slug', element: <Login /> },
    { path: '/activate/account/:token', element: <Activate /> },

    { path: '/*', element: <Page404 /> }
  ]
};

export default authRoute;