import Processing from './lumen.form';
import hookup, { linkApi } from './hookup.axios';
import { courses } from './faker'

export const LumenForm = new Processing();

/* ------------------ overt ------------------ */

// Auth
export const SingIn = async (data: any) => await hookup('post', '/auth/sing/in', data);
export const SingUp = async (data: any) => await hookup('post', '/auth/sing/up', data);
export const LogOut = async (data: any) => await hookup('post', '/auth/logout', data);
export const SetTypeAccount = async (data: any) => await hookup('post', '/profile/set/type/account', data);
export const SingInFaccebook = `${linkApi}/auth/facebook`;
export const SingInGoogle = async () => await hookup('get', '/auth/google', {});
export const ActivateAccount = async (token: any) => await hookup('get', `/auth/activate/account/${token}`, {});

// Recovery & change pass
export const RecoveryPass = async (data: any) => await hookup('post', '/auth/recovery/password', data);
export const ChangePass = async (data: any) => await hookup('post', '/auth/change/password', data);
export const RecoveryUsername = async (data: any) => await hookup('post', '/auth/recovery/username', data);

// Profile
export const Profile = async (data: any = {}) => await hookup('get', '/profile/details', data);
export const ProfilePublic = async (id: any = null, data: any = {}) => await hookup('get', `profile/public/${id}`, data);
export const ProfileUpdate = async (data: any) => await hookup('put', '/profile/details', data);
export const DiscEvaluate = async (data: any) => await hookup('put', '/profile/disc', data);

// Files
export const FileUpload = `${linkApi}/file/upload`;
export const FileCreate = async (data: any) => await hookup("post", `/file/upload`, data);
export const FileRemove = async (data: any) => await hookup("delete", `/file/remove`, data);
export const FileProductGalleryUpdate = async (data: any) => await hookup("put", `/file/product/gallery`, data);

// Options
export const ListRegions = async (data = {}) => await hookup('get', '/auth/regions', data);
export const ListCommunes = async () => await hookup('get', '/auth/communes', {});

// Empresas [/business/job/]
export const BusinessUpdate = async (data: any, id: any) => await hookup('put', `/profile/business/${id}`, data);
export const BusinessCurrent = async () => await hookup('get', `/profile/business/current`, {});
export const BusinessShow = async (id: any) => await hookup('get', `/profile/business/${id}`, {});

// Facturas [/invoice/]
export const InvoiceMonth = async (data: any) => await hookup('get', `/profile/invoice/month`, data);

/* ------------------ Admin ------------------ */

// Usuarios [/admin/user/]
export const UsersList = async (data: any) => await hookup('get', '/admin/user/list', data);
export const UserShow = async (data: any, id: any) => await hookup('get', `/admin/user/${id}`, data);
export const UserCreate = async (data: any) => await hookup('post', '/admin/user', data);
export const UserUpdate = async (data: any, id: any) => await hookup('put', `/admin/user/${id}`, data);
export const UserDelete = async (data: any, id: any) => await hookup('delete', `/admin/user/${id}`, data);
export const UserStatus = async (data: any) => await hookup('put', '/admin/user/status', data);


export const RolesSelect = async () => await hookup('get', '/admin/role/select', {});


// Formularios [/admin/form/]
export const FormsList = async (data: any) => await hookup('get', '/admin/form/list', data);
export const FormShow = async (data: any, id: any) => await hookup('get', `/admin/form/${id}`, data);
export const FormCreate = async (data: any) => await hookup('post', '/admin/form', data);
export const FormUpdate = async (data: any, id: any) => await hookup('put', `/admin/form/${id}`, data);
export const FormDelete = async (data: any, id: any) => await hookup('delete', `/admin/form/${id}`, data);


// Entradas [/admin/post/]
export const PostsList = async (data: any) => await hookup('get', '/admin/post/list', data);
export const PostShow = async (data: any, id: any) => await hookup('get', `/admin/post/${id}`, data);
export const PostCreate = async (data: any) => await hookup('post', '/admin/post', data);
export const PostUpdate = async (data: any, id: any) => await hookup('put', `/admin/post/${id}`, data);
export const PostDelete = async (data: any, id: any) => await hookup('delete', `/admin/post/${id}`, data);

// Empresas [/admin/business/]
export const BusinessList = async (data: any = {}) => await hookup('get', `/admin/business/list`, data);
export const AdminBusinessShow = async (id: any) => await hookup('get', `/admin/business/${id}`, {});
export const AdminBusinessUpdate = async (id: any, data: any = {}) => await hookup('put', `/admin/business/${id}`, data);

// Empresas [/admin/jobs/]
export const AdminJob = async (data: any = {}) => await hookup('get', `/admin/jobs/list`, data);

// Empresas [/admin/reports/]
export const AdminReportUsers = async () => await hookup('get', `/admin/reports/users`, {});


/* ------------------ Admin ------------------ */

// Dashboards
export const AdminDashboard = async (data: any = {}) => await hookup('get', '/admin/dashboard', data);
export const ClientDashboard = async (data: any = {}) => await hookup('get', '/profile/client/dashboard', data);


// Perfil de usuario [/profile]
export const ProfileForm = async () => await hookup('get', `/profile/form`, {});
export const SelectForm = async (type = "none") => await hookup('get', `/profile/select/${type}`, {});

// Empleos [/profile/job/]
export const JobsList = async (data: any) => await hookup('get', '/profile/job/list', data);
export const JobShow = async (id: any) => await hookup('get', `/profile/job/${id}`, {});
export const JobCreate = async (data: any) => await hookup('post', '/profile/job', data);
export const JobUpdate = async (data: any, id: any) => await hookup('put', `/profile/job/${id}`, data);
export const JobDelete = async (data: any, id: any) => await hookup('delete', `/profile/job/${id}`, data);

export const JobReplyShow = async (id: any) => await hookup('get', `/profile/job/${id}/reply`, {});
export const JobReplyUpdate = async (data: any, id: any) => await hookup('put', `/profile/job/${id}/reply`, data);

export const ReplyShow = async () => await hookup('get', `/profile/reply`, {});
export const ReplyUpdate = async (data: any) => await hookup('put', `/profile/reply`, data);


export const JobMatch = async (id: any) => await hookup('put', `/profile/job/${id}/match`, {});
export const JobMatchCandidates = async (id: any, data: any = {}) => await hookup('get', `/profile/job/${id}/match/candidates`, data);

export const CurseList = async () => courses;

// Candidates
export const CandidateSet = async (data: any) => await hookup('post', `/profile/candidate/set`, data)

// Question
export const JobQuestionSet = async (data: any) => await hookup('post', `/profile/question/job/set`, data)
export const JobQuestionShow = async (jobId: any) => await hookup('get', `/profile/question/${jobId}/show`, {})

// Match
export const MatchSend = async (data: any) => await hookup('put', `/profile/send/match`, data);
export const JobActiveContract = async (id: any, data: any) => await hookup('put', `profile/job/active_contract/${id}`, data);
export const JobActiveHired = async (id: any, data: any) => await hookup('put', `profile/job/hired/${id}`, data);

export const Match = async (data = {}) => await hookup('get', `/profile/match`, data);
export const MatchCount = async () => await hookup('get', `/profile/job/match/count`, {});
export const MatchJob = async (id: any) => await hookup('get', `profile/job/${id}/match`, {});
export const MatchJobUpdate = async (id: any, data: any) => await hookup('put', `profile/job/${id}/match/update`, data);
export const MatchJobUpdateJson = async (id: any, data: any) => await hookup('put', `profile/job/${id}/match/update/json`, data);
export const MatchJobReject = async (id: any, data: any) => await hookup('put', `profile/job/${id}/reject`, data);
export const MatchJobApproved = async (id: any, data: any) => await hookup('put', `profile/job/${id}/approved`, data);

export const CandidateList = async (job_id: any, data: any) => await hookup('get', `profile/candidates/${job_id}/list`, data);

export const JobCandidateContract = async (data: any) => await hookup('get', `/contract_annex`, data);
export const ContractWorkus = async (data: any) => await hookup('get', `/contract_workus`, data);

// Search 
export const FindBusiness = async (data: any) => await hookup('put', '/profile/find/business', data);

// Account 
export const StatusAccount = async () => await hookup('get', '/profile/status/account', {})

// calendar 
export const CreateEvent = async (data: any) => await hookup('post', '/calendar/event', data);
export const CheckPlan = async (data: any) => await hookup('post', '/check/plan', data);


// Flow 
export const FlowCreateCustomer = async (data: any) => await hookup('post', '/flow/create', data);
export const FlowDeleteCustomer = async (data: any = {}) => await hookup('delete', '/flow/delete', data);
export const FlowShowCustomer = async (data: any = {}) => await hookup('get', '/flow', data);
export const FlowAddCreditCard = async (data: any = {}) => await hookup('get', '/flow/add/credit/card', data);
