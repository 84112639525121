import { titleLoader } from "../reducers/loader";
import { types } from "../types";

interface PropsFullScreeLoader {
    on:boolean,title:string;
}
const LoadingfullScreen = (info:PropsFullScreeLoader) => {
    if (!info.title) info.title = titleLoader
    return {
        type: types.run_loader,
        payload: {full_screen:{ ...info }}
    };
}

const Loader = { LoadingfullScreen }

export default Loader
