import React from 'react';
import { Link, Outlet } from 'react-router-dom';

import SvgGallery from '../../components/Svg';
const LayoutAuth = (props:any) => {
  return(
    <div className="container-fluid  h-100" style={{minHeight:'100vh'}}>
        <div className="row">
          <div className="col align-self-start p-3 position-fixed text-end circle-brand">
            <Link to="/">
              <SvgGallery.LogoSvg className="logo-main"/>
            </Link>
          </div>
        </div>
        <Outlet/>
        {/* {props.children} */}
		</div>
  )
}

export default LayoutAuth;