import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging"
import { isSupported } from "firebase/messaging"
 
const firebaseConfig = {
  apiKey: "AIzaSyDg1NCsXzMvV9Ge8PQgwzGaajlbmCBeNOU",
  authDomain: "work-us-65715.firebaseapp.com",
  projectId: "work-us-65715",
  storageBucket: "work-us-65715.appspot.com",
  messagingSenderId: "611729816575",
  appId: "1:611729816575:web:1f54dc099ad240244105ca",
  measurementId: "G-1WWM11FTR1"
};
export const messaging = () => {

  try  {
    const app:any = initializeApp(firebaseConfig);
    // const analytics = getAnalytics(app);
    // getAnalytics(app);
    const run = async() => {
      let check = await isSupported()
      if(check) {
       return  await getMessaging(app);
      }
      console.info('Firebase Messagingss isSupported', check);
      return null
    }
    return run()
  } catch (FirebaseError) {
    console.error('Failed to initialize Firebase Messagingss', FirebaseError);
  }
}




/*import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";
//import { getAnalytics } from "firebase/analytics";

var firebaseConfig = {
  apiKey: "AIzaSyDg1NCsXzMvV9Ge8PQgwzGaajlbmCBeNOU",
  authDomain: "work-us-65715.firebaseapp.com",
  projectId: "work-us-65715",
  storageBucket: "work-us-65715.appspot.com",
  messagingSenderId: "611729816575",
  appId: "1:611729816575:web:1f54dc099ad240244105ca",
  measurementId: "G-1WWM11FTR1"
};

let messaging:any = {}
let firebaseApp:any = {}

try {
  firebaseApp = initializeApp(firebaseConfig);
  if (firebaseApp.messaging.isSupported()) {
      console.log('firebase.workus',firebaseApp);
  }
  //const analytics = getAnalytics(firebaseApp);
  messaging = getMessaging(firebaseApp);
} catch (error) {
  console.log('firebase.workus',error);
}

export const FirebaseToken = (isTokenFound:any,runToken:any) => {
  if(isTokenFound) return false
  return getToken(messaging, {vapidKey: 'BKfnD5Zgtf3YYDal7wKWnM_z5NkvM9LcPzwYAEIhah0HIIzLM2HwV_Dhx2llcmIp_3iyupZchqBYvDAZ04V5HmU'}).then((currentToken) => {
    if (currentToken && isTokenFound) {
      console.log('firebase.workus','current token for client: ', currentToken);
      runToken(currentToken)
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('firebase.workus','No registration token available. Request permission to generate one.');
      runToken(currentToken)
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('firebase.workus','An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve:any) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
});*/