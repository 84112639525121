import { InfoSession } from "../../api/session";
import { types } from "../types";

const check = () => {
    return {
        type: types.login,
        payload: { infoSession: InfoSession() }
    };
}

const Auth = { check }

export default Auth

