import axios from 'axios';
import { InfoSession } from './session';
//console.log("process.env",process.env)

export const linkApi = process.env.REACT_APP_ENV_API_URL ?? null;

const encodeQueryString = (filters: any) => {
    let url = '';
    Object.keys(filters).forEach((key) => {
        if (Array.isArray(filters[key])) {
            url += `${key}=`;
            filters[key].map((item: any) => {
                if (item !== null) {
                    url += `${item},`;
                }
                return true;
                // url += this.encodeQueryStringFromArray(key, item, index) + '&'
            });
            url += '&'
        } else if (filters[key]) {
            url += `${key}=${filters[key]}&`;
        }
    });
    return url.substring(0, url.length - 1);
};

const hookup = async (method: any, url: string, data: object, linkIp: any = linkApi) => {
    let mainResponse = {
        data: [],
        validations: null,
    };
    const query = encodeQueryString(data);
    url = method === 'get' ? `${url}?${query}` : url;
    let config = {
        method: method,
        baseURL: linkIp,
        url: url,
        data: data,
        headers: {
            sensitive: true,
            Authorization: InfoSession().authorization,
        },
    };
    mainResponse = await axios(config)
        .then(function (response) {
            return contentCheck(response)
        })
        .catch(function (error) {
            console.info(`Ups! ocurrio un error toma un capture y reportalo: \n\n[${method}] ${linkApi}${url}\n\n${error}`);
            const response = error.response;
            ///alert(error)
            if (response.status === 422) {
                mainResponse.validations = response.data;
                return mainResponse;
            }
            return mainResponse;
        });
    return mainResponse;
};
export default hookup;

export const contentCheck = (response: any) => {
    const contentType = response.headers['content-type'];

    if (contentType.includes('application/json')) {
        return response.data;
    }
    if (contentType.includes('text/csv')) {
        downloadFile(response, 'text/csv');
    }

    return { contentType }

}

export const downloadFile = (response: any, mimeType: string) => {
    const blob = response.data
    const responseUrl = response.config.url;
    const fileName = responseUrl.substring(responseUrl.lastIndexOf('/') + 1) || 'download.csv';
    const url = window.URL.createObjectURL(new Blob([blob], { type: mimeType }));
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
}
