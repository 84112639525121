import PropTypes from 'prop-types';
import React from 'react';
import { Navigate } from 'react-router-dom';

export const RedirectLogin = (props:any) => {
  return props?.isAuthenticated ? props?.children : <Navigate to="/login" replace />;
};
export const RedirectLogut = (props:any) => {
  return !props?.isAuthenticated ? props?.children : <Navigate to="/" replace />;
};

RedirectLogin.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired, // Esto debería ser bool si siempre devuelve true o false
  children: PropTypes.node.isRequired // `node` es lo que se usa para elementos de React, no `func`
};

RedirectLogut.propTypes = {
  isAuthenticated: PropTypes.bool.isRequired, // Esto debería ser bool si siempre devuelve true o false
  children: PropTypes.node.isRequired // `node` es lo que se usa para elementos de React, no `func`
};
