import { types } from '../types';

import { InfoSession } from '../../api/session';

export const initialState : any = {
	info: InfoSession(),
};

const Auth = (state = initialState, action: any) => {
	switch (action.type) {
		case types.login:
			return {
				...state,
				info: action.payload.infoSession,
			};
		case types.logout:
			return {
				...state,
				info: action.payload.infoSession,
			};

		default:
			return state;
	}
};

export default Auth;