import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const useHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const hash = location.hash;
    if (hash) {
      const id = hash.replace("#", "");
      setTimeout(() => {  // Añadiendo setTimeout
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 900); // 500 milisegundos de retraso
    }
  }, [location]);

  const push = (path: any, state: any = null) => {
    if (state) {
      navigate(path, { state });
    } else {
      navigate(path);
    }
  };

  const replace = (path: string, state: any) => {
    navigate(path, { state, replace: true });
  };

  const go = (n: any) => {
    navigate(n);
  };

  const goBack = () => {
    go(-1);
  };

  const goForward = () => {
    go(1);
  };

  return {
    push,
    replace,
    go,
    goBack,
    goForward,
    location
  };
};

export default useHistory;
// import { useNavigate, useLocation } from 'react-router-dom';

// const useHistory = () => {
//   const navigate = useNavigate();
//   const location = useLocation();

//   const push = (path: any, state: any = null) => {
//     if (state) {
//       navigate(path, { state });
//     }
//     navigate(path);

//   };

//   const replace = (path: string, state: any) => {
//     navigate(path, { state, replace: true });
//   };

//   const go = (n: any) => {
//     navigate(n);
//   };

//   const goBack = () => {
//     go(-1);
//   };

//   const goForward = () => {
//     go(1);
//   };

//   return {
//     push,
//     replace,
//     go,
//     goBack,
//     goForward,
//     location
//   };
// };

// export default useHistory;