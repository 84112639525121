import { types } from '../types';

export const initialState: any = {
	ask_to_activate_payment_method: false,
};

const Account = (state = initialState, action: any) => {
	switch (action.type) {
		case types.account_status:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default Account;