import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'




import { 
  faCheckSquare,
  faCoffee,
  faCheckCircle,
  faAddressCard,
  faShieldAlt,
  faPercent,
  faAngleRight,
  faUsers,
  faMedal,
  faVideo,
  faPhone,
  faTrashAlt,
  faPlus,
  faPencilAlt,
  faPencilRuler
} from '@fortawesome/free-solid-svg-icons'
library.add(fab,
  far,fas,
  faCheckSquare,
  faCoffee,
  faCheckCircle,
  faAddressCard,
  faShieldAlt,
  faPercent,
  faAngleRight,
  faUsers,
  faMedal,
  faVideo,
  faPhone,
  faTrashAlt,
  faPlus,
  faPencilAlt,
  faPencilRuler
)