import  {ReactComponent as LogoSvg}  from '../../assets/svg/logo.svg';
import  {ReactComponent as LogoNameSvg}  from '../../assets/svg/logo_name.svg';
import  {ReactComponent as GoogleSvg}  from '../../assets/svg/icon_google.svg';
import  {ReactComponent as MetaSvg}  from '../../assets/svg/icon_meta.svg';
import  {ReactComponent as UndrawOnlineConnection}  from '../../assets/svg/undraw_online_connection_6778.svg';
import  Fallabella  from '../../assets/svg/logo-banco-falabella.svg';
import  Avatar8  from '../../assets/svg/avatar_8.jpg';
import  AvatarNone  from '../../assets/svg/avatar_none.jpeg';
import  Goal  from '../../assets/svg/goal.png';

import  Retail1  from '../../assets/svg/retail-1.png';
import  Retail2  from '../../assets/svg/retail-2.png';
import  Retail3  from '../../assets/svg/retail-3.png';
import  Retail4  from '../../assets/svg/retail-4.png';
import  Retail5  from '../../assets/svg/retail-5.png';
import  {ReactComponent as LogoFlow}  from '../../assets/svg/logo-flow.svg';
import  ImgHeadUserPerfil  from '../../assets/svg/bg-perfil.png';


const SvgGallery = {
    LogoSvg,
    LogoNameSvg,
    UndrawOnlineConnection,
    GoogleSvg,
    MetaSvg,
    Fallabella,
    Avatar8,
    AvatarNone,
    Retail1,
    Retail2,
    Retail3,
    Retail4,
    Retail5,
    ImgHeadUserPerfil,
    Goal,
    LogoFlow
}

export default SvgGallery