import { lazy } from 'react';
import LayoutAdmin from '../../components/Layouts/LayoutAdmin'
import Loadable from '../../components/Loadings/Loadable'

const Dashboard= Loadable(lazy(() => import('../../pages/Admin/Dashboard')))
const Blog= Loadable(lazy(() => import('../../pages/Admin/Blog')))
const BlogForm= Loadable(lazy(() => import('../../pages/Admin/Modules/Blog/BlogForm')))
const Busines= Loadable(lazy(() => import('../../pages/Admin/Busines')))
const BusinessDetail= Loadable(lazy(() => import('../../pages/Admin/Modules/Busines/BusinessDetail')))
const CandidateDetails= Loadable(lazy(() => import('../../pages/Company/Jobs/CandidateDetails')))
const FormList= Loadable(lazy(() => import('../../pages/Admin/Modules/FormsCustom/FormList')))
const FormCreate= Loadable(lazy(() => import('../../pages/Admin/Modules/FormsCustom/FormCreate')))
const NewJob= Loadable(lazy(() => import('../../pages/Company/Jobs/NewJob')))
const Page404 = Loadable(lazy(() => import('../../pages/404')));

const backofficeRoute = {
  path: '/',
  element: <LayoutAdmin />,
  children: [
    { path:'/', element:<Dashboard/>},
    { path:'/blog', element:<Blog/>},
    { path:'/blog/crear', element:<BlogForm/>},
    { path:'/post/:id', element:<BlogForm/>},

    { path:'/empresas', element:<Busines/>},
    { path:'/empresas/crear', element:<BlogForm/>},
    { path:'/empresas/:id', element:<BusinessDetail/>},

    { path:'/formularios', element:<FormList/>},
    { path:'/formulario/crear', element:<FormCreate/>},
    { path:'/formulario/:id/edit', element:<FormCreate/>},
    { path:'/perfil/:id/details', element:<CandidateDetails/>},
    { path:'/trabajo/:jobId', element:<NewJob/>},
    { path: '/*', element: <Page404/> }
  ]
};

export default backofficeRoute;