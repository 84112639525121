import { lazy } from 'react';
import LayoutUserSidebar from '../../components/Layouts/LayoutUserSidebar'
import Loadable from '../../components/Loadings/Loadable'

const Dashboard = Loadable(lazy(() => import('../../pages/Person/Dashboard')));
const Feedback = Loadable(lazy(() => import('../../pages/Person/Feedback')));
const ProfileUser = Loadable(lazy(() => import('../../pages/Auth/ProfileUser')));
const ProfileUserEdit = Loadable(lazy(() => import('../../pages/Auth/ProfileUserEdit')));
const OfferDetails = Loadable(lazy(() => import('../../pages/Person/OfferDetails')));
const FormParity = Loadable(lazy(() => import('../../pages/Person/Form/FormParity')));
const FormDisc = Loadable(lazy(() => import('../../pages/Person/FormDisc')));
const Page404 = Loadable(lazy(() => import('../../pages/404')));
const Activate = Loadable(lazy(() => import('../../pages/Activate')));

const userRoute = {
  path: '/',
  element: <LayoutUserSidebar />,
  children: [
    { path: '/', element: <Dashboard /> },
    { path: '/persona', element: <Dashboard /> },
    { path: '/retroalimentacion', element: <Feedback /> },
    { path: '/formulario', element: <FormParity /> },
    { path: 'perfil', element: <ProfileUser /> },
    { path: '/perfil/editar', element: <ProfileUserEdit /> },
    { path: '/formulario/disc', element: <FormDisc /> },
    { path: '/oferta/:slug', element: <OfferDetails /> },
    { path: '/activate/account/:token', element: <Activate /> },
    { path: '/*', element: <Page404 /> }
  ]
};

export default userRoute;