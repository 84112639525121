
const Footer = () => {
  return (
    <div className="pb-4 pb-md-0" style={{ zIndex: -2 }}>
      <div className="constainer-fluid shadow-1 bg-white p-4 text-black mb-5 mb-md-3" style={{ zIndex: -2 }}>
        <div className="row">
          <div className="col-12 col-md-4">
            <h5 className="fw-bold">Web</h5>
            <hr className="hr-1" />
            <ul className="nav flex-column">
              <li className="nav-item mb-2"><a href="https://workus.cl/blog" className="nav-link p-0 text-muted">Blog</a></li>
              <li className="nav-item mb-2"><a href="https://workus.cl/contacto" className="nav-link p-0 text-muted">Contacto</a></li>
              <li className="nav-item mb-2"><a href="https://workus.cl/nosotros" className="nav-link p-0 text-muted">Nosotros</a></li>
            </ul>
          </div>

          <div className="col-12 col-md-4">
            <h5 className="fw-bold">Información</h5>
            <hr className="hr-1" />
            <ul className="nav flex-column">
              <li className="nav-item mb-2"><a href="https://workus.cl/politicas-de-privacidad" className="nav-link p-0 text-muted">Políticas de privacidad</a></li>
              <li className="nav-item mb-2"><a href="https://workus.cl/condiciones-de-servicio" className="nav-link p-0 text-muted">Condiciones del servicio</a></li>
              <li className="nav-item mb-2"><a href="https://workus.cl/eliminacion-de-datos-de-usuario" className="nav-link p-0 text-muted">Eliminación de datos</a></li>
            </ul>
          </div>

          <div className="col-12 col-md-3">
            <h5 className="fw-bold">App</h5>
            <hr className="hr-1" />
            <ul className="nav flex-column">
              <li className="nav-item mb-2"><a href="/" className="nav-link p-0 text-muted">Inicio</a></li>
              <li className="nav-item mb-2"><a href="/perfil" className="nav-link p-0 text-muted">Perfil</a></li>
            </ul>
          </div>

          {/* <div className="col-12 col-md-5">
          <form>
            <h5>Suscríbete a nuestro newsletter</h5>
            <p>Resumen mensual de lo que es nuevo y emocionante de nosotros.</p>
            <div className="d-flex w-100 gap-2">
              <label className="visually-hidden">Correo electronico</label>
              <input id="newsletter1" type="text" className="form-control" placeholder="Correo electronico"/>
              <button className="btn btn-primary" type="button">Subscribe</button>
            </div>
          </form>
        </div> */}
        </div>
        <hr className="hr-1" />
        <div className="d-flex justify-content-between small">
          <p className="small text-center text-md-end w-100 mb-0 small">
            <strong>V1.9.0</strong> © <strong>{new Date().getFullYear()}</strong> WORK US SPA.  All rights reserved.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer;