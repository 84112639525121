import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';

// routes
import { RedirectLogin, RedirectLogut } from '../paths/protected';
import LayoutAuth from '../../components/Layouts/LayoutAuth';

import authRoute from '../paths/auth';
import userRoute from '../paths/user';
import backofficeRoute from '../paths/backoffice';
import companyRoute from '../paths/company';

export default function ThemeRoutes() {

  const element: any = useRoutes(authRoute.children);
  const { isLogged, type } = useSelector((state: any) => state.auth.info);
  const roleBasedRoutes: any = getRoutesByRole(type);
  console.log(isLogged)
  const routes = useRoutes([
    {
      ...roleBasedRoutes,
      element: <RedirectLogin isAuthenticated={isLogged}>{roleBasedRoutes.element}</RedirectLogin>
    },
    {
      ...authRoute,
      element: <RedirectLogut isAuthenticated={isLogged}><LayoutAuth>{element}</LayoutAuth></RedirectLogut>,
    }
  ]);

  return routes;
}

export const getRoutesByRole = (role: string) => {
  switch (role) {
    case 'usuario':
      return userRoute;
    case 'empresa':
      return companyRoute;
    case 'back-office':
      return backofficeRoute;
    default:
      return {
        path: '/',
        element: <h4 className="text-center pt-5">Tu cuenta tiene un problema..</h4>,
        children: []
      } // o alguna ruta por defecto
  }
}