// import AvatarUser from '../Icons/AvatarUser'
import { Avatar } from 'rsuite';
import { InfoSession } from '../../api/session';


const AvatarEntity = (props:any) => {
  const data:any = InfoSession()
  return(
    <>
      {
        data?.avatar === 'undefined' || data?.avatar === undefined || data?.avatar==="null" ? 
        <Avatar
          circle
          style={{ background: '#000' }}
          size={props.size ? props.size : "lg"}
          className={props.className ? props.className : ""}
        >
          {data.name ? `${data.name.charAt(0)}`.toUpperCase() : 'U'} {/*data.paternal_surname ? data.paternal_surname.charAt(0) : ''*/}
        </Avatar>
        : <Avatar
          size={props.size ? props.size : "lg"}
          className={props.className ? props.className : ""}
          circle
          src={data.avatar}
        />
      }
      
    </>
  )
}

export default AvatarEntity;