import { deleteTokenFirebase } from "../firebase-run";

//const pefix=process.env.REACT_APP_ENV_PREFIX_LOCAL;
export const InfoSession = () => {
	return {
		authorization: localStorage.getItem(`authorization`),
		fullName: localStorage.getItem(`full_name`),
		name: localStorage.getItem(`name`),
		paternal_surname: localStorage.getItem(`paternal_surname`),
		avatar: localStorage.getItem(`avatar`),
		isLogged: (`authorization` in localStorage) && localStorage.getItem(`google_session`) == null,
		type: localStorage.getItem(`perfil`),
		profile: localStorage.getItem(`profile`),
	};
}

export const OpenSession = (data: any, complete: any = "") => {
	let body: any = {
		authorization: localStorage.setItem(`authorization`, data.authorization),
		fullName: localStorage.setItem(`full_name`, data.fullName),
		name: localStorage.setItem(`name`, data.name),
		paternal_surname: localStorage.setItem(`paternal_surname`, data.paternal_surname),
		avatar: localStorage.setItem(`avatar`, data.avatar),
		status: localStorage.setItem(`status`, data.status),
		username: localStorage.setItem(`username`, data.username),
	};

	if (complete !== "") {
		body[complete] = localStorage.setItem(complete, 'run')
	} else {
		localStorage.removeItem(`google_session`);
		localStorage.removeItem(`facebook_session`);
	}
	return body
}

export const UpdateSession = (data: any) => {
	return {
		fullName: localStorage.setItem(`full_name`, data.fullName),
		name: localStorage.setItem(`name`, data.name),
		paternal_surname: localStorage.setItem(`paternal_surname`, data.paternal_surname),
		avatar: localStorage.setItem(`avatar`, data.avatar),
		status: localStorage.setItem(`status`, data.status),
		username: localStorage.setItem(`username`, data.username),
	};
}

export const SetTypeAccount = (type: any) => {
	localStorage.setItem(`perfil`, type)
}

export const CloseSession = async () => {
	await deleteTokenFirebase()
	localStorage.removeItem(`authorization`);
	localStorage.removeItem(`full_name`);
	localStorage.removeItem(`name`);
	localStorage.removeItem(`paternal_surname`);
	localStorage.removeItem(`avatar`);
	localStorage.removeItem(`status`);
	localStorage.removeItem(`username`);
	localStorage.removeItem(`perfil`);
	localStorage.removeItem(`on_notifications`);
}

export const SaveProfile = (data: any) => {
	localStorage.setItem(`profile`, JSON.stringify(data))
}
export const GetProfile = () => {
	return JSON.parse(localStorage.getItem(`profile`) || 'null');
}



export const SaveFroms = (data: any) => {
	localStorage.setItem(`forms`, JSON.stringify(data))
}
export const GetFroms = () => {
	return JSON.parse(localStorage.getItem(`forms`) || '[]');
}


export const SaveSingUp = (data: any) => {
	localStorage.setItem(`sing_up`, JSON.stringify(data))
}
export const GetSingUp = () => {
	return JSON.parse(localStorage.getItem(`sing_up`) || '[]');
}


// FireBase

export const SaveOnNotifications = (data: any) => {
	localStorage.setItem(`on_notifications`, data)
}
export const GetOnNotifications = () => {
	let aux = localStorage.getItem(`on_notifications`)
	if (aux === null)
		return 'show_ms'
	if (aux === 'false')
		return false
	if (aux === 'true')
		return true
}

export const SetBodySession = (data: any) => {
	let authorization: string = "";
	let fullName: string = "";
	let status: string = "";
	let username: string = "";
	let name: string = "";
	let paternal_surname: string = "";
	let avatar: any = null;
	let check: boolean = false
	if (data.session) {
		authorization = data.session.authorization
		fullName = data.session.full_name
		name = data.session.name
		paternal_surname = data.session.paternal_surname
		avatar = data.session.avatar && data.session.avatar !== undefined ? data.session.avatar.s3_url : 'null'
		status = data.session.status
		username = data.session.username
	}
	if (authorization !== '') {
		check = true
		OpenSession({
			authorization,
			fullName,
			name,
			paternal_surname,
			avatar,
			status,
			username,
		});
		SetTypeAccount(data.session.roles[0].frontend)
		SaveProfile({
			role: data.session.roles
		})
		SaveSingUp({})

	}
	return check
}