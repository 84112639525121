export const courses = {
  data: [
    {
      slug:"1",
      title:'Curso KAM',
      img:"https://platformuploads.s3.amazonaws.com/media/8134/7414/158699297418321.png?Signature=VYiWpc9Y8RoqPfPell3ff0ftnrk%3D&Expires=1656343963&AWSAccessKeyId=AKIAJOIWZCRTROUA2YMQ",
      state:'REALIZADO',
      description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    },
    {
      slug:"2",
      title:'Curso de programación 1',
      img:"https://zone-assets-api.vercel.app/assets/images/e-learning/course_7.jpg",
      state:'REALIZADO',
      description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    },
    {
      slug:"3",
      title:'Curso de SEO',
      img:"https://boluda.com/files/curso-seo.gif",
      state:'REALIZADO',
      description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    },
    {
      slug:"4",
      title:'Curso de dibujo',
      img:"https://culturacolectiva-cultura-colectiva-prod.cdn.arcpublishing.com/resizer/hJVy6YOPbpbatrmQXa9WTGjIqyA=/1024x768/filters:format(jpg):quality(70)/cloudfront-us-east-1.images.arcpublishing.com/culturacolectiva/5F65K6J4MJFIFK6NYAKD3XAP3I.jpeg",
      state:'REALIZADO',
      description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    },
    {
      slug:"5",
      title:'Habilidades esenciales de cocina de un profesional',
      img:"https://oleico.com/wp-content/uploads/2020/04/original-74e7f6d1e420d36188b05b9e65551502.jpeg",
      state:'REALIZADO',
      description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    },
    {
      slug:"6",
      title:'El arte de la fotografía en blanco y negro',
      img:"https://cdn.pixabay.com/photo/2015/07/28/19/05/camera-864757_960_720.jpg",
      state:'REALIZADO',
      description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    },
    {
      slug:"7",
      title:'Tu guía completa de fotografía',
      img:"https://capturetheatlas.com/wp-content/uploads/2020/05/Camera-lens-.jpg",
      state:'REALIZADO',
      description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    },
    {
      slug:"8",
      title:'Tutorial básico de 1 hora de WordPress',
      img:"https://s.w.org/style/images/about/WordPress-logotype-wmark.png",
      state:'REALIZADO',
      description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    },
    {
      slug:"9",
      title:'Comprender la Psicología del Color',
      img:"https://t2.uc.ltmcdn.com/es/posts/0/1/7/psicologia_de_los_colores_que_es_y_significados_51710_orig.jpg",
      state:'REALIZADO',
      description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    },
    {
      slug:"10",
      title:'CURSOS OS-10 GUARDIAS DE SEGURIDAD',
      img:"https://anspe.ec/images/joomlart/news/image-17.jpg",
      state:'REALIZADO',
      description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    },
    {
      slug:"11",
      title:'PANADERÍA ARTESANAL PRESENCIAL',
      img:"https://impulsapopular.com/wp-content/uploads/2019/05/4400-Pasos-para-abrir-una-panader%C3%ADa-de-%C3%A9xito.jpg",
      state:'REALIZADO',
      description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    },
    {
      slug:"12",
      title:'REPOSTERÍA Y DECORACIÓN PRESENCIAL',
      img:"https://corbuse.edu.mx/blog/wp-content/uploads/2016/09/CORBUS%C3%89-Cursos-de-reposteria-y-panaderia-en-mexico.jpg",
      state:'REALIZADO',
      description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    },
    {
      slug:"13",
      title:'Administración de Seguros para no Especialistas II-T 2022',
      img:"https://www.micinsurance.net/wp-content/uploads/2016/11/Corredores-de-seguros.jpg",
      state:'REALIZADO',
      description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    },
    {
      slug:"14",
      title:'Contabilidad en Base a IFRS II-T 2022',
      img:"https://icei-formacion.com/wp-content/uploads/2019/11/3170-scaled.jpg",
      state:'REALIZADO',
      description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    },
    {
      slug:"15",
      title:'Seguros de Transporte Marítimo, Terrestre, Aéreo y Casco II-T 2022',
      img:"https://www.navicon.cl/wp-content/uploads/2019/03/5424-20190312074039.jpg",
      state:'REALIZADO',
      description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    },
    {
      slug:"16",
      title:'Mecánica básica automotriz ',
      img:"https://educacion-continua.aiep.cl/wp-content/uploads/2020/07/banner-curso-mecanicaautomotriz.jpg",
      state:'REALIZADO',
      description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    },
    {
      slug:"17",
      title:'Mantención Mecánica Industrial',
      img:"https://diiexperto.com/wp-content/uploads/2022/02/ind-mech.jpg",
      state:'REALIZADO',
      description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    },
    {
      slug:"18",
      title:'Curso para ser cuidador de niños (canguro o babysitter)',
      img:"https://static4.depositphotos.com/1001001/390/i/600/depositphotos_3903938-stock-photo-mother-and-daughter-drawing-together.jpg",
      state:'REALIZADO',
      description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    },
    {
      slug:"19",
      title:'OPTIMIZACIÓN Y ANÁLISIS DE DATOS EN EXCEL AVANZADO',
      img:"https://educacion-continua.aiep.cl/wp-content/uploads/2020/07/EXCEL_2.jpg",
      state:'REALIZADO',
      description:"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book."
    },


    
  ]
}
