import * as React from 'react';
import { useSelector } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function FullScreenCircularProgress() {
  const data = useSelector((state:any) => state.loader); // Asume que 'loading' es el nombre del estado en tu store
  if (!data.full_screen.on) return null
  return (
    <Box
      sx={{
        position: 'fixed', // Fija el componente en la pantalla
        display: 'flex',
        top: 0,
        left: 0,
        width: '100%', // Cubre todo el ancho
        height: '100%', // Cubre toda la altura
        justifyContent: 'center', // Centra horizontalmente
        alignItems: 'center', // Centra verticalmente
        backgroundColor: 'rgba(255, 255, 255, 0.7)', // Fondo blanco semitransparente
        zIndex: 1031 // Asegura que se muestre por encima de otros elementos
      }}
    >
      <div className="row">
        <div className="col-12 text-center">
          <CircularProgress/>
        </div>
        <div className="col-12 text-center">
          {data.full_screen.title}
        </div>
      </div>
    </Box>
  );
}