import React, { useEffect, useState } from 'react';
import { Icon, IconButton, InputGroup, Input, List, Badge } from 'rsuite'
import { Link, Outlet } from "react-router-dom";
import { GetProfile, InfoSession } from '../../api/session';

import SvgGallery from '../../components/Svg';
import AvatarEntity from '../../components/Img/AvatarEntity';
import DrawerRsuite, { drawerSample } from '../Sidebars/DrawerRsuite';
import Footer from './Footer'
//import AlertContract from '../../pages/Company/AlertContract';
import useHistory from '../../hooks/useHistory';
import { DropdownMenu } from './NavBarBottom';
import { NavItem, Navbar, Sidenav, SidenavBody } from '../Nav/NavSimple';
import AlertProfile from '../../pages/Company/AlertProfile';
import { StatusAccount } from '../../api/end.points';
import Actions from '../../redux/actions';
import { useDispatch } from 'react-redux';
import AlertCreditCard from '../../pages/Company/AlertCreditCard';

const LayoutUserSidebar = (props: any) => {
  const dispatch = useDispatch()
  const history = useHistory();
  const pathname = history.location.pathname.split('/');

  const [activeSide, setActiveSide] = useState(`/admin/${pathname[2]}`);
  const [drawer, setDrawer]: any = useState({ ...drawerSample })
  const noti: any = []

  const handleSelect = (activeKey: any) => {
    setActiveSide(activeKey)
  };

  const heandlerDrawer = (details: any = {}) => {
    drawer.show = !drawer.show;

    drawer.heandler = heandlerDrawer;
    drawer.title = <><Icon icon="bell-o" className="me-3" />Notificaciones</>;
    drawer.BtnTitle = () => <>
    </>;
    setDrawer({ ...drawer })
  }

  useEffect(() => {
    const run = async () => {
      const status = await StatusAccount()
      if (status?.data) {
        await dispatch(Actions.Account.check(status?.data));
      }
    }
    run()
  }, [dispatch, pathname])


  return (
    <div className="container-fluid">
      <div className="row" style={{ minHeight: '100vh' }}>
        <div className="col-auto col-sm-4 col-md-auto d-none d-sm-block" style={{ minHeight: '100vh' }}>
          <nav className="navbar sticky-top navbar-light navbar-expand row">
            <div className="col">
              <div className="my-3 text-center">
                <Link to="/" className="navbar-brand me-0">
                  <SvgGallery.LogoSvg className="logo-nav" />
                </Link>
              </div>
              <SideRsuite active={activeSide} appearance="subtle" onSelect={handleSelect} />
            </div>
          </nav>
        </div>

        <div className="col col-sm-8 col-md" style={{ minHeight: '100vh' }}>
          <nav className="navbar sticky-top navbar-light navbar-expand row py-3">
            <div className="container-fluid ">
              <div className="row mx-0 bg-white shadow-1 w-100">
                <div className="col d-flex py-3">
                  <Link to="/" className="navbar-brand me-0 d-block d-sm-none">
                    <SvgGallery.LogoSvg className="logo-nav" />
                  </Link>
                  <InputGroup inside style={{ width: "300px" }} className="d-none d-sm-block">
                    <Input placeholder="¿Qué estás buscando?" />
                    <InputGroup.Button>
                      <Icon icon="search" />
                    </InputGroup.Button>
                  </InputGroup>
                  <div className="d-flex ms-auto">

                    <Badge>
                      <IconButton icon={
                        <AvatarEntity />
                      } circle className="ms-3 p-0 nav-avatar" onClick={() => history.push("/perfil")} />
                    </Badge>

                    <Badge content="0"><IconButton icon={<Icon icon="bell-o" />} circle className="ms-3" onClick={heandlerDrawer} /></Badge>

                    <DropdownMenu className="ms-3" />


                    <IconButton icon={<Icon icon="bars" />} circle className="ms-3 d-block d-sm-none" />
                  </div>
                  <DrawerRsuite {...drawer} size="xs">
                    <List hover className="list-notification">
                      {
                        noti.length > 0 ? noti.map((item: any, index: number) => (
                          <List.Item key={index} index={index}>
                            <div className="row">
                              <div className="col-auto">
                                <img src={SvgGallery.Fallabella} alt="" className="avatar" />
                              </div>
                              <div className="col ps-0 small">
                                <strong className="small">Banco Fallabella</strong>
                                <p className="small mb-2">
                                  Hiciste match con el puesto de trabajo <strong>"Asistente RRHH"</strong>
                                </p>
                                <small className="small text-muted d-block"><Icon icon="clock-o" /> 13 hace minutos</small>
                              </div>
                            </div>
                          </List.Item>
                        ))
                          :
                          <div className="row">
                            <div className="col ps-0 small text-center py-5">
                              <strong className="small">Sin notificaciones</strong>
                            </div>
                          </div>
                      }
                    </List>
                  </DrawerRsuite>
                </div>
              </div>
            </div>
          </nav>
          {/* {props.children} */}
          <Outlet />
          <hr className="hr-1" />
          <Footer />
          {/* <AlertContract /> */}
          <AlertProfile />
          <AlertCreditCard />
        </div>
      </div>
    </div>
  );
};

export default LayoutUserSidebar

const MyLink = React.forwardRef((props: any, ref: any) => {
  const { href } = props;
  return (
    <Link to={href} className="rs-nav-item-content" role="button" onClick={props.onClick}>
      {props.children}
    </Link>
  );
});

const NavLink = (props: any) => <NavItem componentClass={MyLink} {...props} />;


const SideRsuite = (props: any) => {
  const history = useHistory();

  return (
    <Sidenav defaultOpenKeys={['3', '4']} activeKey="1" className="sidebar-layout-user">
      <SidenavBody>
        <Navbar>
          <NavItem componentClass={() => <hr className="hr-1" />} />
          <NavItem eventKey={'1'} className="avatar" onSelect={() => history.push("/perfil")}>
            <div className="row mx-0">
              <div className="col-auto pe-2">
                <AvatarEntity size="md" />
              </div>
              <div className="col text-left ps-0">
                <span className="name m-0 small">{InfoSession().fullName}</span>
                <br />
                <span className="name m-0 small text-muted">{GetProfile()?.role[0]?.name}</span>
              </div>
            </div>
          </NavItem>
          <NavItem componentClass={() => <hr className="hr-1" />} />
          <NavLink href="/" eventKey={'1'}>
            <Icon icon='dashboard' />
            <span>Inicio</span>
          </NavLink>
          {/* <NavLink href="/retroalimentacion" eventKey={'2'}>
            <Icon icon='mortar-board' />
            <span>Match</span>
            
          </NavLink>
          /billing
          <NavLink href="/noticias" eventKey={'3'}>
            <Icon icon='globe' />
            <span>Estadísticas</span>
          </NavLink> */}
          <NavLink href="/billing" eventKey={'3'}>
            <Icon icon='calculator' />
            <span>Facturas</span>
          </NavLink> */
          <NavLink
            onSelect={() => {
              window.location.href = 'https://workus.cl';
            }}
            eventKey="3"
          >
            <Icon icon='hand-peace-o' />
            <span>Conócenos!</span>
          </NavLink>
          {/* <li className="rs-nav-item">
          <a href="https://workus.cl" className="rs-nav-item-content">
            <Icon icon='hand-peace-o' />
            <span>Conócenos!</span>
          </a>
          </li> */}
          <NavItem componentClass={() => <hr className="hr-1" />} />
        </Navbar>
      </SidenavBody>
      {/*
      <NavItem eventKey="2" icon={<Icon icon="group" />}>
        User Group
      </NavItem>
      <Dropdown eventKey="3" title="Advanced" icon={<Icon icon="magic" />}>
        <Dropdown.Item eventKey="3-1">Geo</Dropdown.Item>
        <Dropdown.Item eventKey="3-2">Devices</Dropdown.Item>
        <Dropdown.Item eventKey="3-3">Loyalty</Dropdown.Item>
        <Dropdown.Item eventKey="3-4">Visit Depth</Dropdown.Item>
      </Dropdown>
      <Dropdown
        eventKey="4"
        title="Settings"
        icon={<Icon icon="gear-circle" />}
      >
        <Dropdown.Item eventKey="4-1">Applications</Dropdown.Item>
        <Dropdown.Item eventKey="4-2">Channels</Dropdown.Item>
        <Dropdown.Item eventKey="4-3">Versions</Dropdown.Item>
        <Dropdown.Menu eventKey="4-5" title="Custom Action">
          <Dropdown.Item eventKey="4-5-1">Action Name</Dropdown.Item>
          <Dropdown.Item eventKey="4-5-2">Action Params</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>*/}
    </Sidenav>
  )
}