import { types } from "../types";

const check = (result: any) => {
    console.log("nueva condiciones", result)
    return {
        type: types.account_status,
        payload: { ...result }
    };
}

const Account = { check }

export default Account

