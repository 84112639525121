import { types } from '../types';
export const titleLoader = "Cargando.."
export const initialState : any = {
	full_screen: {on:false,title:"Cargando.."},
};

const Loaders = (state = initialState, action: any) => {
	switch (action.type) {
		case types.run_loader:
			return {
				...state,
				...action.payload,
			};
		default:
			return state;
	}
};

export default Loaders;