import { Provider } from 'react-redux';
import FirebaseCore from './firebase-run';
import store from './redux/store';
import ThemeRoutes from './routes/new';
// import MiddlewareAuth from './routes/MiddlewareAuth';
import { BrowserRouter } from 'react-router-dom';
import FullScreenCircularProgress from './components/Loadings/FullScreenCircularProgress';

const App = () => {
  return (
    <Provider store={store}>
      <FirebaseCore />
      {/* <MiddlewareAuth/> */}
      <BrowserRouter basename={"/"}>
        <ThemeRoutes />
      </BrowserRouter>
      <FullScreenCircularProgress />
    </Provider>
  );
};
export default App
