import { useEffect, useState } from "react";
import { Button, Modal } from "rsuite";
import { useLocation } from 'react-router-dom'
import * as Api from '../../api/end.points';
import useHistory from "../../hooks/useHistory";

const AlertProfile = () => {
  const [state, setState] = useState({ show: false })
  const [profile, setProfile] = useState(0)
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const path: any = location.pathname
    if (path !== '/perfil' && path !== '/billing') {
      const run = async () => {
        let data: any = await Api.BusinessCurrent()
        setState({ show: data?.data?.contract_active === 0 ? true : false });
        setProfile(data?.data?.roles[0].id)
      }
      run()
    }
  }, [location])

  const close = () => {
    setState({ show: false });
    history.push('/perfil')
  }

  return (
    <div className="modal-container">
      <Modal backdrop="static" show={state.show} onHide={close} size="xs">
        <Modal.Body className="small">
          {
            profile === undefined ?
              <>Perfil no defnido!</>
              :
              <>
                <h5>¡Hola! 👋</h5>
                <p>Somos una plataforma con más de 3,000 usuarios buscando trabajo, lo que nos convierte en el lugar perfecto para encontrar talento. ¡Sigue estos pasos y haz match!</p>
                <ol>
                  <li className="mb-2">📝 <strong>Completa tu perfil</strong></li>
                  <li className="mb-2">🚀 <strong>Crea tu oferta:</strong> Describe qué necesitas.</li>
                  <li className="mb-2">💞 <strong>Explora candidatos:</strong> Mira quién encaja.</li>
                  <li className="mb-2">✅ <strong>Elige tus favoritos:</strong> Decide con quién avanzar.</li>
                  <li className="mb-2">💌 <strong>Envía solicitudes de match:</strong> Así, los candidatos sabrán que estás interesado en ellos.</li>
                  <li className="mb-2">📬 <strong>Recibe respuesta:</strong> El candidato podrá aceptar o rechazar tu solicitud, lo cual se te notificará por correo electrónico.</li>
                  <li className="mb-2">🎉 <strong>¡Y eso es todo!</strong> Coordina los detalles finales directamente con el condidato.</li>
                </ol>
                <p>En cada paso, estamos aquí para ayudarte a encontrar el match perfecto. ¡Empecemos esta aventura juntos!</p>
                <p>Saludos,</p>
                <p><strong>El Equipo de WORKUS</strong> 🤝</p>
              </>
          }

        </Modal.Body>
        <Modal.Footer>
          <Button onClick={close} appearance="primary">
            Ir al Perfil
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AlertProfile;