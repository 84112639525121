import { lazy } from 'react';
import LayoutCompanySidebar from '../../components/Layouts/LayoutCompanySidebar'
import Loadable from '../../components/Loadings/Loadable'
// Working
const NewPageJobs = Loadable(lazy(() => import('../../pages/Company/Jobs/NewMain')))

const Dashboard = Loadable(lazy(() => import('../../pages/Company/Dashboard')))
const NewJob = Loadable(lazy(() => import('../../pages/Company/Jobs/NewJob')))
const FormsJobs = Loadable(lazy(() => import('../../pages/Company/Jobs/FormsJobs')))
const FormsEndJobs = Loadable(lazy(() => import('../../pages/Company/Jobs/FormsEndJobs')))
const ListApplicants = Loadable(lazy(() => import('../../pages/Company/Jobs/Applicants/ListApplicants')))
const CandidateDetails = Loadable(lazy(() => import('../../pages/Company/Jobs/CandidateDetails')))
const ShortListed = Loadable(lazy(() => import('../../pages/Company/Jobs/ShortListed/ShortListed')))
const ScheduleCandidates = Loadable(lazy(() => import('../../pages/Company/Jobs/ShortListed/ScheduleCandidates')))
const FormQuestions = Loadable(lazy(() => import('../../pages/Company/Jobs/Generators/FormQuestions')))
const JobDetails = Loadable(lazy(() => import('../../pages/Company/Jobs/JobDetails')))
const ProfileUser = Loadable(lazy(() => import('../../pages/Auth/ProfileUser')))
const ProfileUserEdit = Loadable(lazy(() => import('../../pages/Auth/ProfileUserEdit')))
const ListBilling = Loadable(lazy(() => import('../../pages/Company/billing/ListBilling')))

const Page404 = Loadable(lazy(() => import('../../pages/404')));
const Activate = Loadable(lazy(() => import('../../pages/Activate')));

const companyRoute = {
  path: '/',
  element: <LayoutCompanySidebar />,
  children: [
    { path: '/empresa', element: <Dashboard /> },
    { path: '/', element: <Dashboard /> },
    { path: '/trabajo/formularios/:jobId', element: <FormsJobs /> },
    { path: '/trabajo/finalizar', element: <FormsEndJobs /> },
    { path: '/trabajo/pagina', element: <NewPageJobs /> },
    { path: '/trabajo/nuevo', element: <NewPageJobs /> },
    { path: '/oferta/:jobId', element: <NewPageJobs /> },
    { path: '/trabajo/:jobId', element: <NewPageJobs /> },
    { path: '/trabajo/:jobId/:tab', element: <NewPageJobs /> },
    { path: '/trabajo/nuevo/v1', element: <NewJob /> },
    { path: '/trabajo/:jobId/v1', element: <NewJob /> },
    { path: '/trabajo/:jobId/postulantes/v1', element: <ListApplicants /> },
    { path: '/trabajo/:id/seleccionados', element: <ShortListed /> },
    { path: '/trabajo/:id/agendar', element: <ScheduleCandidates /> },
    { path: '/trabajo/:jobId/generador', element: <FormQuestions /> },
    { path: '/trabajo/:id/detalles', element: <JobDetails /> },

    { path: '/billing', element: <ListBilling /> },

    { path: '/perfil', element: <ProfileUser /> },
    { path: '/perfil/editar', element: <ProfileUserEdit /> },
    { path: '/perfil/:id/details', element: <CandidateDetails /> },
    { path: '/activate/account/:token', element: <Activate /> },

    { path: '/*', element: <Page404 /> }
  ]
};

export default companyRoute;
