import React from 'react';
import { Drawer, Button } from 'rsuite';

const DrawerRsuite = (props:any) => {
	const { show, heandler, title, BtnTitle, BtnFooter, size} = props;
	const close = () => {
		heandler();
	}
	const childrenWithProps = React.Children.map(props.children, child => {
      // checking isValidElement is the safe way and avoids a typescript error too
      if (React.isValidElement(child)) {
          //return React.cloneElement(child, { ...props });
          return React.cloneElement(child);
      }
      return child;
  });

	return(
		<div>
			<Drawer
				size={size ? size: "md"}
				show={show}
				onHide={close}
			>
				<Drawer.Header>
					<Drawer.Title>
						<div className="row justify-content-between align-items-center mx-0">
							<div className="col-auto">
								<strong>{title}</strong>
							</div>
							<div className="col-auto">
								<BtnTitle/>
							</div>
						</div>
					</Drawer.Title>
				</Drawer.Header>
				<Drawer.Body>
					{childrenWithProps}
				</Drawer.Body>
				<Drawer.Footer className="pb-4">
					<Button onClick={close} appearance="subtle">Cerrar</Button>
					<BtnFooter/>
				</Drawer.Footer>
			</Drawer>
		</div>
	);
}
export default DrawerRsuite;

export const drawerSample = {
	show:false,
	details:{},
	heandler:()=>{ alert('Funcion de cierre') },
	title:'Ejemplo de Drawer',
	BtnTitle: () => <></>,
	BtnFooter: () => <></>
}