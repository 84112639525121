import { useEffect, useState } from "react";
import { Button, Modal } from "rsuite";
import { useLocation } from 'react-router-dom'
import useHistory from "../../hooks/useHistory";
import { useSelector } from "react-redux";

const AlertCreditCard = () => {
    const { ask_to_activate_payment_method } = useSelector((state: any) => state.account);
    const [state, setState] = useState({ show: false })
    const location = useLocation();
    const history = useHistory();

    useEffect(() => {
        const path: any = location.pathname
        if (path !== '/perfil' && path !== '/billing' && path !== '/perfil/') {
            const run = async () => {
                setState({ show: ask_to_activate_payment_method });
                console.log("ok", ask_to_activate_payment_method)
            }
            run()
        }
    }, [ask_to_activate_payment_method, location.pathname])

    const close = () => {
        setState({ show: false });
        history.push('/perfil/#flow')
    }

    return (
        <div className="modal-container">
            <Modal backdrop="static" show={state.show} onHide={close} size="xs">
                <Modal.Body className="small">
                    <h5>🌟 ¡Felicidades! 🎉</h5>
                    <p>¡<strong>Más de tres candidatos</strong> han aceptado tus solicitudes de match! Estás en el camino correcto para encontrar las conexiones perfectas.</p>
                    <p>Para seguir explorando y conectándote con más perfiles increíbles, necesitamos que realices un pequeño pero importante paso</p>
                    <ol>
                        <li className="mb-2">📝 <strong>Ingresar tus datos en formulario para FLOW</strong></li>
                        <li className="mb-2">🚀 <strong>Añadir tu tarjeta en FLOW</strong></li>
                        <li className="mb-2">💞 <strong>¡Listo!</strong> Puedes continuar con tu proceso.</li>
                    </ol>

                    <p>En cada paso, estamos aquí para ayudarte a encontrar el match perfecto. ¡Empecemos esta aventura juntos!</p>
                    <p>Saludos,</p>
                    <p><strong>El Equipo de WORKUS</strong> 🤝</p>

                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={close} appearance="primary">
                        Ir al Perfil
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default AlertCreditCard;