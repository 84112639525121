import React, { useState } from 'react'
// import {getAuth, signInAnonymously} from "firebase/auth";
import { getToken, onMessage, deleteToken } from "firebase/messaging";
import { messaging } from "./firebase";

import { Drawer, Button } from 'rsuite';
import { GetOnNotifications, SaveOnNotifications } from './api/session';
import { LogOut } from './api/end.points';
import { useSelector } from 'react-redux';


export const getTokenFirebase = async () => {
  const accept: any = GetOnNotifications()
  if (accept === true) {
    let token = null
    try {
      let info: any = await messaging()
      token = await getToken(info, {
        vapidKey: "BKfnD5Zgtf3YYDal7wKWnM_z5NkvM9LcPzwYAEIhah0HIIzLM2HwV_Dhx2llcmIp_3iyupZchqBYvDAZ04V5HmU"
      }).catch(error => console.log("Ups! un error al generar el token"));
      if (token) console.log("Token generado exitosamente!", token);
      if (!token) console.log("EL toquen no pudo ser generado");
    } catch (err) {
      console.info('Failed getTokenFirebase', err);
    }
    return token
  }
}

const deleteAllCookies = async () => {
  var cookies = document.cookie.split(";");

  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}
export const deleteTokenFirebase = async () => {
  try {
    let info: any = await messaging()
    let token: any = await getTokenFirebase()
    await LogOut({ firebase_token: token })
    let data = await deleteToken(info).catch(error => console.log("Ups! no se puedo elimienar el token"))
    console.log('delete.success', data)
  } catch (e) {
    //console.info(e); // Logs the error
  }
  await deleteAllCookies()
}

const FirebaseCore = () => {

  const [show, setShow] = useState(false)
  const { isLogged } = useSelector((state: any) => state.auth.info);


  const activate = () => {
    SaveOnNotifications(true)
    getTokenFirebase()
    setShow(false)
  }
  const notActivate = () => {
    SaveOnNotifications(false)
    setShow(false)
  }

  React.useEffect(() => {
    const run = async () => {
      try {
        let info: any = await messaging()
        onMessage(info, (message: any) => {
          console.log("tu mensaje:", message);
          //alert(message.notification.title);
        })
      } catch (e) {
        //console.info(e); // Logs the error
      }
    }
    run()
  }, []);

  React.useEffect(() => {
    const accept: any = GetOnNotifications()
    if (isLogged === false && accept === "show_ms") {
      setShow(true)
    }

  }, [isLogged])



  return (
    <Drawer show={show} onClose={() => true} placement="bottom" className="notification-drawer">
      <Drawer.Header>
        <Drawer.Title className="text-black">Deseas activar las <strong>notificaciones</strong>?</Drawer.Title>
      </Drawer.Header>
      <Drawer.Body className=" border border-0">
        Las notificaciones te ayudaran a enterarte de las ultimas <strong>ofertas laborales</strong>, actualizaciones y noticias en el workus.cl.
      </Drawer.Body>
      <Drawer.Footer className="pb-0">
        <Button appearance="primary" onClick={activate}>
          Confirmar
        </Button>
        <Button appearance="subtle" onClick={() => notActivate()}>
          Cancelar
        </Button>
      </Drawer.Footer>
    </Drawer>
  )
  // <button onClick={activarMensajes}> Recibir noti</button>
}

export default FirebaseCore