import React, { useState } from 'react';
import { Icon, IconButton, InputGroup, Input, Badge } from 'rsuite'
import {  Link} from "react-router-dom";
//import { useDispatch } from 'react-redux';
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { InfoSession } from '../../api/session';
//import  Actions from '../../redux/actions';
import { Outlet } from 'react-router-dom';


import SvgGallery from '../../components/Svg';
import AvatarEntity from '../../components/Img/AvatarEntity';
//import DrawerRsuite, {drawerSample} from '../Sidebars/DrawerRsuite';
import Footer from './Footer'
import NavBarBottom, { DrawerNotification, DropdownMenu } from './NavBarBottom';
import useHistory from '../../hooks/useHistory';
import { NavItem, Navbar, Sidenav, SidenavBody } from '../Nav/NavSimple';



const LayoutUserSidebar = ( props:any  ) => {
  const history = useHistory();
	// const dispatch = useDispatch();
  const pathname = history.location.pathname.split('/');
	
  const [activeSide, setActiveSide ] = useState(`/admin/${pathname[2]}`);
	// const [expand, setExpand] = React.useState(true);
  //const [drawer, setDrawer]:any = useState({...drawerSample})
  
	const handleSelect = (activeKey:any) => {
		setActiveSide(activeKey)
	};

	// const handleCloseSession = () => {
	// 	CloseSession();
	// 	//dispatch(Actions.Auth.logout());
	// };
  // const close = async() => {
  //   await CloseSession()
  //   window.location.replace("/");
  // };

  // const heandlerDrawer = (details:any={}) => {
	// 	drawer.show=!drawer.show;


	// 	drawer.heandler = heandlerDrawer;
	// 	drawer.title=<><Icon icon="bell-o" className="me-3"/>Notificaciones</>;
	// 	drawer.BtnTitle= () => <>
  //   </>;
	// 	setDrawer({...drawer})
	// }


  return (
    <div className="container-fluid">
      <div className="row" style={{minHeight:'100vh'}}>
        <div className="col-auto d-none d-sm-block col-sm-4 col-md-auto" style={{minHeight:'100vh'}}>
          <nav className="navbar sticky-top navbar-light navbar-expand row">
            <div className="col">
              <div className="my-3 text-center">
                <Link to="/" className="navbar-brand me-0">
                  <SvgGallery.LogoSvg className="logo-nav"/>
                </Link>
              </div>
              <SideRsuite active={activeSide}  appearance="subtle" onSelect={handleSelect}/>
            </div>
          </nav>
        </div>

        <div className="col col-sm-8 col-md" style={{minHeight:'100vh'}}>
          <nav className="navbar sticky-top navbar-light navbar-expand row py-3">
            <div className="container-fluid ">
              <div className="row mx-0 bg-white shadow-1 w-100">
              <div className="col d-flex py-3">
                <Link to="/" className="navbar-brand me-0 d-block d-sm-none">
                  <SvgGallery.LogoSvg className="logo-nav"/>
                </Link>
                <InputGroup inside style={{width:"300px"}} className="d-none d-sm-block">
                  <Input  placeholder="¿Qué estás buscando?" />
                  <InputGroup.Button>
                    <Icon icon="search" />
                  </InputGroup.Button>
                </InputGroup>
                <div className="d-flex ms-auto">
                  <div className="ms-3">
                    <Badge>
                      <IconButton icon={
                      <AvatarEntity/>
                      } circle className="p-0 nav-avatar" onClick={()=>history.push("/perfil")}/>
                    </Badge>
                  </div>

                  <div className="ms-3 d-none d-sm-flex">
                    <DrawerNotification/>
                  </div>

                  <div className="ms-3 d-none d-sm-flex">
                    <DropdownMenu/>
                  </div>

                  <div className="ms-3 d-block d-sm-none">
                    <IconButton icon={<Icon icon="bars" />} circle/>
                  </div>
                </div>
              </div>
              </div>
            </div>
          </nav>
          {/* {props.children} */}
          <Outlet/>
          <hr className="hr-1"/>
          <Footer/>
          <NavBarBottom/>
        </div>
      </div>
		</div>
  );
};

export default LayoutUserSidebar

const MyLink = React.forwardRef((props:any, ref:any) => {
  const { href } = props;
  return (
    <Link to={href} className="rs-nav-item-content" role="button" onClick={props.onClick}>
      {props.children}
    </Link>
  );
});

const NavLink = (props:any) => <NavItem componentClass={MyLink} {...props} />;


const SideRsuite = (props:any) => {
  const history = useHistory();

  return(
    <Sidenav defaultOpenKeys={['3', '4']} activeKey="1" className="sidebar-layout-user">
      <SidenavBody>
        <Navbar>
          <NavItem componentClass={() => <hr className="hr-1"/> } />
          <NavItem eventKey={'1'} className="avatar" onSelect={()=>history.push("/perfil")}>
            <div className="row mx-0">
              <div className="col-auto pe-2">
                <AvatarEntity size="md"/>
              </div>
              <div className="col text-left ps-0">
                <span className="name m-0 small">{InfoSession().fullName}</span>
                <br/>
                <button className="btn btn-link m-0 small p-0">Mi Perfil</button>
                {/* <br/>
                <span className="name m-0 small text-muted">{InfoSession().type}</span> */}
              </div>
            </div>
          </NavItem>
          <NavItem componentClass={() => <hr className="hr-1"/> } />
          <NavLink href="/" eventKey={'1'}>
            <Icon icon='dashboard' />
            <span>Inicio</span>
          </NavLink>
          <NavLink href="/retroalimentacion" eventKey={'2'}>
            <Icon icon='mortar-board' />
            <span>Cursos</span>
            
          </NavLink>
          <NavLink href="/noticias" eventKey={'3'}>
            <Icon icon='globe' />
            <span>Noticias</span>
          </NavLink>
          <NavLink href="/noticias" eventKey={'3'}>
            <Icon icon='hand-peace-o' />
            <span>Conócenos!</span>
          </NavLink>
          <NavItem componentClass={() => <hr className="hr-1"/> } />
        </Navbar>
      </SidenavBody>
      {/*
      <NavItem eventKey="2" icon={<Icon icon="group" />}>
        User Group
      </NavItem>
      <Dropdown eventKey="3" title="Advanced" icon={<Icon icon="magic" />}>
        <Dropdown.Item eventKey="3-1">Geo</Dropdown.Item>
        <Dropdown.Item eventKey="3-2">Devices</Dropdown.Item>
        <Dropdown.Item eventKey="3-3">Loyalty</Dropdown.Item>
        <Dropdown.Item eventKey="3-4">Visit Depth</Dropdown.Item>
      </Dropdown>
      <Dropdown
        eventKey="4"
        title="Settings"
        icon={<Icon icon="gear-circle" />}
      >
        <Dropdown.Item eventKey="4-1">Applications</Dropdown.Item>
        <Dropdown.Item eventKey="4-2">Channels</Dropdown.Item>
        <Dropdown.Item eventKey="4-3">Versions</Dropdown.Item>
        <Dropdown.Menu eventKey="4-5" title="Custom Action">
          <Dropdown.Item eventKey="4-5-1">Action Name</Dropdown.Item>
          <Dropdown.Item eventKey="4-5-2">Action Params</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>*/}
    </Sidenav>
  )
}