import { types } from "../types";
import { Dispatch } from "redux";

export const AutoComplete = async (  keywords : string, dispatch : Dispatch) => {
	dispatch({
    type : types.search_main_results,
    payload : [{label:keywords,value:keywords,loading:true}]
	})
	const list:any = [] //await Api.AutoComplete({search:keywords});
  if ( typeof list.data === 'object' && list.data.length > 0 ) {
  	return dispatch({
      type : types.search_main_results,
      payload : list.data
  	})
  } else {
   return dispatch({
      type : types.search_main_results,
      payload :  [{label:keywords,value:keywords,notFound:true}]
    })
  }
}

