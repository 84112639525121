import { useState } from 'react';
import { Icon, Dropdown, IconButton, List, Badge } from 'rsuite'
import { CloseSession } from '../../api/session';

import SvgGallery from '../../components/Svg';
//import AvatarEntity from '../../components/Img/AvatarEntity';
import DrawerRsuite, { drawerSample } from '../Sidebars/DrawerRsuite';
import useHistory from '../../hooks/useHistory';
import { useDispatch } from 'react-redux';
import Actions from '../../redux/actions';

const NavBarBottom = () => {
  const history = useHistory();
  //const pathname = history.location.pathname.split('/');

  // const [activeSide, setActiveSide ] = useState(`/admin/${pathname[2]}`);


  // const handleSelect = (activeKey:any) => {
  // 	setActiveSide(activeKey)
  // };
  return (
    <>
      <div className="row py-3  d-block d-sm-none">
        <div className="fixed-bottom col-12 py-3">
          <div className="row mx-0 bg-white shadow-1 w-100 justify-content-between">
            <div className="col-auto py-3">
              <DrawerNotification />
            </div>

            <div className="col-auto py-3">
              <IconButton icon={<Icon icon="home" />} circle onClick={() => history.push("/")} />
            </div>

            <div className="col-auto py-3">
              <DropdownMenu placement="topEnd" />
            </div>

          </div>
        </div>
      </div>
    </>
  )
}

export default NavBarBottom

export const DrawerNotification = () => {
  const [drawer, setDrawer]: any = useState({ ...drawerSample })
  //const [noti, setNoti]:any = useState([])
  const noti: any = []

  const heandlerDrawer = (details: any = {}) => {
    drawer.show = !drawer.show;


    drawer.heandler = heandlerDrawer;
    drawer.title = <><Icon icon="bell-o" className="me-3" />Notificaciones</>;
    drawer.BtnTitle = () => <>
    </>;
    setDrawer({ ...drawer })
  }
  return (
    <>
      <Badge content="0"><IconButton icon={<Icon icon="bell-o" />} circle onClick={heandlerDrawer} /></Badge>
      <DrawerRsuite {...drawer} size={"xs"}>
        <List hover className="list-notification">
          {
            noti.length > 0 ? noti.map((item: any, index: number) => (
              <List.Item key={index} index={index}>
                <div className="row">
                  <div className="col-auto">
                    <img src={SvgGallery.Fallabella} alt="" className="avatar" />
                  </div>
                  <div className="col ps-0 small">
                    <strong className="small">Banco Fallabella</strong>
                    <p className="small mb-2">
                      Hiciste match con el puesto de trabajo <strong>"Asistente RRHH"</strong>
                    </p>
                    <small className="small text-muted d-block"><Icon icon="clock-o" /> 13 hace minutos</small>
                  </div>
                </div>
              </List.Item>
            ))
              :
              <div className="row">
                <div className="col ps-0 small text-center py-5">
                  <strong className="small">Sin notificaciones</strong>
                </div>
              </div>
          }
        </List>
      </DrawerRsuite>
    </>
  )
}

export const DropdownMenu = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch()
  const close = async () => {
    const { Loader, Auth } = Actions
    const Loading = Loader.LoadingfullScreen
    await dispatch(Loading({ on: true, title: "Cerrando Sesión" }))
    await CloseSession()
    await dispatch(Auth.check());
    history.push("/")
    dispatch(Loading({ on: false, title: "" }))
  };
  return (
    <Dropdown title="bottomEnd" placement={props.placement ? props.placement : 'bottomEnd'}
      className={props.className ? props.className : ''}
      renderTitle={() => {
        return <IconButton icon={<Icon icon="cog" />} circle />;
      }}
    >
      <Dropdown.Item onSelect={() => history.push("/perfil")}>
        <Icon icon="user" /> Perfil
      </Dropdown.Item>
      <Dropdown.Item onSelect={() => close()}>
        <Icon icon="group" /> Cerrar sesión
      </Dropdown.Item>
    </Dropdown>
  )
}