import { Nav, Sidenav as SidenavRsuite } from "rsuite";

export const NavItem = (props:any) => {
  const { ...restProps } = props;
  return <Nav.Item {...restProps}/>;
};

export const Navbar = (props:any) => {
  const { ...restProps } = props;
  return (
    <Nav {...restProps}/>
  );
};

export const Sidenav = (props:any) => {
  const { ...restProps } = props;
  return (
    <SidenavRsuite {...restProps}/>
  );
};

export const SidenavBody = (props:any) => {
  const { ...restProps } = props;
  return (
    <SidenavRsuite.Body {...restProps}/>
  );
};